/*
ООП
• Пустой класс
• Добавление методов
• Добавление полей
• Конструктор
• Описывающий прямоугольник для точек
• Морской бой (имитация поля)
• Наследование
• super
• переопределение методов

Java
• Создайте пустой пакет с названием oop1. Внутри него создайте класс Main. В том же файле над классом Main создайте пустой класс A. Создайте два объекта класса A, и запишите их в переменные a и b. Выведите два объекта этого класса

Ожидаемый вывод (значение после @ может отличаться):
oop1.A@5fdef03a
oop1.A@48cf768c

• Добавьте в класс A метод foo, который ничего не принимает, ничего не возвращает, и выводит на экран строку "A foo". В методе main вызовите метод foo на каждом из созданных объектов

Ожидаемый вывод:
oop1.A@5fdef03a
oop1.A@48cf768c
A foo
A foo

• Добавьте в класс A целочисленное поле x. В методе main после создания объектов запишите в x первого объекта значение 10, а в x второго 20. Выведите x из первого объекта, затем из второго

Ожидаемый вывод:
oop1.A@5fdef03a
oop1.A@48cf768c
A foo
A foo
10
20

• Добавьте в класс A метод bar, который ничего не принимает, ничего не возвращает, и выводит на экран строку "A bar" и значение x через пробел. Вызовите метод bar на каждом из объектов

Ожидаемый вывод:
oop1.A@5fdef03a
oop1.A@48cf768c
A foo
A foo
10
20
A bar 10
A bar 20

• Добавьте в класс A конструктор, который принимает целое число a в качестве параметра и записывает его в x. Затем закомментируйте строки, в которых записывали x в объектах. Вместо этого вставьте 10 в аргументы при создании первого объекта, и 20 при создании второго. Вывод не должен измениться

Ожидаемый вывод:
oop1.A@5fdef03a
oop1.A@48cf768c
A foo
A foo
10
20
A bar 10
A bar 20

• Переименуем параметр конструктора с a на x. Возникает конфликт имён. В области видимости конструктора есть две переменные с именем x (поле класса и параметр метода). Если обратиться к переменной просто по имени x, будет взята переменная из параметра. Теперь, если мы хотим взять переменную x из поля класса, нужно писать this.x. Давайте укажем, что присваиваем x в поле класса с именем x. Вывод не должен измениться

Ожидаемый вывод:
oop1.A@5fdef03a
oop1.A@48cf768c
A foo
A foo
10
20
A bar 10
A bar 20

• Сейчас поле x доступно извне класса. Ничто не мешает нам напрямую обращаться к этому полю на объектах. В ООП так делать не принято. Доступ к полям объектов извне принято осуществлять только через методы. Для этого, во-первых, поставим приватную область видимости для поля x. Для этого нужно  прописать слово private перед объявлением. Тперь наш код не будет компилироваться, поскольку у нас есть обращение к полю x в методе main

• Теперь давайте добавим ещё один метод в класс A, назовём его getX, который ничего не принимает, а возвращает целое число x из поля класса. Такие методы называют геттеры (как правило, они не делают ничего, кроме как возвращают значение поля). Код по-прежнему не компилируется. Чтобы это исправить, нужно заменить прямое обращение к полю x на вызов метода getX. Сделайте это и проверьте, что вывод не изменился

Ожидаемый вывод:
oop1.A@5fdef03a
oop1.A@48cf768c
A foo
A foo
10
20
A bar 10
A bar 20

• А как быть, если мы хотим изменить значение x в объекте? В таких случаях тоже используют методы. Давайте добавим в класс A метод setX, который принимает целое x в качестве параметра и записывает его в поле x объекта, ничего не возвращает (помним, что обращение к полю при конфликте имён возможно только через this). Такие методы называют сеттерами. Давайте в методе main для первого объекта установим x со значением 30, а для второго 40. Выведем новые значения иксов с помощью метода bar

Ожидаемый вывод:
oop1.A@5fdef03a
oop1.A@48cf768c
A foo
A foo
10
20
A bar 10
A bar 20
A bar 30
A bar 40

• Само собой, можно добавлять методы любого содержания с любыми именами. Но есть несколько правил по именованию. Название класса всегда начинается с заглавной буквы и является существительным, описывающим какую-то сущность. Поля класса именуются с маленькой буквы и тоже называются существительными (разве что кроме полей типа boolean). Методы именуются с маленькой буквы глаголами

• oop2. LoopedRange
- LoopedRange(int start, int max)
- void next()

• oop3. Объект с простым состоянием наглядно. Показать ссылочность. Например, два последних числа.
- void update(int value)
- int getPrev()
- int getLast()

• oop4. Записная книжка на n номеров
- Notebook(int count)
- void addNote(String note)
- String getNote(int idx)
- void deleteNote(int idx)
- void printAll() - вывод с нумерацией + сколько осталось мест

• oop5. В нём создадим класс Main. В том же файле над Main создадим ещё один класс PointBorder (граница точек). В этом классе будут методы:
- PointBorder(int x, int y) - конструктор, в нём задаётся начальная точка
- void addPoint(int x, int y) - добавление точки
- int getLeft() - возвращает x самой левой точки из уже добавленных
- int getRight() - возвращает x самой правой точки из уже добавленных
- int getTop() - возвращает y самой верхней точки (наименьший y) из уже добавленных
- int getBottom() - возвращает y самой нижней точки (наибольший y) из уже добавленных
- int getWidth() - возвращает расстояние между самой левой и самой правой точкой по x
- int getHeight() - возвращает расстояние между самой верхней и самой нижней точкой по x
- int getSquare() - возвращает площадь получившегося прямоугольника

Поля класса не регламентируются. Добавляете какие считаете необходимыми

пример использования:
PointBorder pb = new PointBorder(5, 2);
pb.addPoint(3, 6);
System.out.println("Left: " + pb.getLeft());
System.out.println("Right: " + pb.getRight());
pb.addPoint(6, 4);
System.out.println("Left: " + pb.getLeft());
System.out.println("Right: " + pb.getRight());
System.out.println("Top: " + pb.getTop());
System.out.println("Bottom: " + pb.getBottom());
System.out.println("Width: " + pb.getWidth());
System.out.println("Height: " + pb.getHeight());
System.out.println("Square: " + pb.getSquare());

ожидаемый вывод:
Left: 3
Right: 5
Left: 3
Right: 6
Top: 2
Bottom: 6
Width: 3
Height: 4
Square: 12

• Сражение воинов и рыцарей
*/

import OopIntro from './OopIntro'

export default [
  {
    component: OopIntro,
    label: '!'
  }
]
