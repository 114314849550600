<template>
  <h2>Объектно-ориентированное программирование</h2>
  <p>Определение из Википедии. Объе́ктно-ориенти́рованное программи́рование (ООП) — методология программирования, основанная на представлении программы в виде совокупности объектов, каждый из которых является экземпляром определённого класса, а классы образуют иерархию наследования.</p>
  <p>Определений много, и каждый понимает его по-разному. В интернете очень много холиваров на эту тему.</p>
  <p>На мой взгляд, ООП - это больше, чем просто классы. Это скорее способность разложить задачу на сущности, имеющие разные зоны ответственности, которые в совокупности решают поставленную задачу.</p>
  <p>Но когда говорят об ООП применительно к языку программирования, обычно имеют в виду определение из Википедии. В рамках этой темы тоже под ООП будем понимать именно это</p>
  <p>Про ООП в JavaScript можно прочитать <a class="link" href="https://learn.javascript.ru/classes" target="_blank">здесь</a>.</p>
  <p>Во фронтенде ООП применяется в очень ограниченном круге задач, поэтому сильно заострять внимание на нём не будем.</p>
  <p>Для того, чтобы немного пощупать его руками, предлагаю сделать стек, очередь, дек и дерево как в предыдущей теме, но с использованием классов (не применяя литералы объектов).</p>
</template>

<script>
export default {
  props: ['task']
}
</script>
